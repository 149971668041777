import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = {
  class: "col-xl-6",
  id: "v-step-0"
}
const _hoisted_3 = {
  class: "col-xl-6",
  id: "v-step-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InsurancePackage = _resolveComponent("InsurancePackage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InsurancePackage, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        background: "abstract-4.svg",
        title: "Motor Insurance",
        avatar: "/media/packages/motor_insurance.png",
        url: `${_ctx.motorURL}`,
        description: "Our Motor Insurance policies cover all forms of damage."
      }, null, 8, ["url"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_InsurancePackage, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        background: "abstract-2.svg",
        title: "Home and Personal Effects Insurance",
        avatar: "/media/packages/home_insurance.png",
        url: "/insurance-packages/house-insurance",
        description: "Insure your home and its contents at affordable rates."
      })
    ])
  ]))
}