
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import InsurancePackage from "@/components/packages/InsurancePackage.vue";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "insurance-packages",
  components: {
    InsurancePackage,
  },
  data() {
    return {
      motorURL: "",
      houseURL: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Insurance Packages");
      MenuComponent.reinitialization();
    });
  },
  mounted: function() {
    // this.$tours["myTour"].start();
  },
  created() {
    this.hasMotorPolicies();

    //Set page title
    document.title = "Insurance Packages | " + process.env.VUE_APP_TITLE;

    // this.$tours["myTour"].start();
  },
  methods: {
    hasMotorPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/business-class/MOT`)
          .then(({ data }) => {
            //Assign data to props
            this.motorURL =
              data.data.length === 0
                ? "/insurance-packages/motor-insurance/quote"
                : "/insurance-packages/motor-insurance/existing-policy-quote";
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
